import { getProvider } from '@wagmi/core'
import React, { createContext, useContext, useMemo, useRef, useState } from 'react'

import { ENS } from '@ensdomains/ensjs'
import type { ContractName } from '@ensdomains/ensjs/contracts/types'

const opts: ConstructorParameters<typeof ENS>[0] = {}

if (process.env.NEXT_PUBLIC_PROVIDER && process.env.NEXT_PUBLIC_DEPLOYMENT_ADDRESSES) {
  const deploymentAddresses = JSON.parse(process.env.NEXT_PUBLIC_DEPLOYMENT_ADDRESSES!) as Record<
    ContractName | 'ENSRegistry',
    string
  >
  opts.getContractAddress = () => (contractName) => deploymentAddresses[contractName]
}

if (process.env.NEXT_PUBLIC_GRAPH_URI) {
  opts.graphURI = process.env.NEXT_PUBLIC_GRAPH_URI
}


// ephemery stuff
const ephemeryAddresses = JSON.parse([
  '{',
    '"ENSRegistry":"0x902740a7Bc8279b1A3beBDf91cf9A016235E8859",',
    '"BaseRegistrarImplementation":"0x2a18772c38A2a909ad1c2Ab1234c3c11e5A29EE3",',
    '"ETHRegistrarController":"0x5F9162eEC0A9d4a9a565646a05196b0F7EB7bE7f",',
    '"NameWrapper":"0x46a05770c66915184FEea838f4B5e582AD651EE8",',
    '"PublicResolver":"0x73177F7E5d6AD4f32fadd4262a858CF6A779dD57",',
    '"ReverseRegistrar":"0x29BC718BD0c91A9430716F229A89e4C796a2409c",',
    '"Multicall":"0x1195eDfF07CC259DF22EF34Ee8FFa7d6C5C0A128"',
  '}'
].join('')) as Record<
  ContractName | 'ENSRegistry',
  string
>;
opts.getContractAddress = () => (contractName) => ephemeryAddresses[contractName];
opts.graphURI = "/graphapi/subgraphs/name/graphprotocol/ens";


const defaultValue: ENS = new ENS(opts)
defaultValue.staticNetwork = true

const EnsContext = createContext({ ...defaultValue, ready: false })

const EnsProvider = ({ children }: { children: React.ReactNode }) => {
  const [ready, setReady] = useState(false)
  const chainIdRef = useRef<number | null>(null)
  const chainSetPromise = useRef<Promise<any> | null>(null)

  const setChainPromise = () => {
    const currentProvider = getProvider()
    const currentChainId = currentProvider.network.chainId
    return defaultValue.setProvider(currentProvider as any).then(() => {
      chainIdRef.current = currentChainId
      chainSetPromise.current = null
      setReady(true)
    })
  }

  useMemo(() => {
    if (typeof window !== 'undefined' && !chainSetPromise.current) {
      chainSetPromise.current = setChainPromise()
    }
  }, [])

  const value = useMemo(
    () =>
      new Proxy(
        { ...defaultValue, ready },
        {
          // chain id safety check
          get(target, prop, reciever) {
            const targetFn = target[prop as keyof typeof target]
            // if on client + target is async function
            if (
              typeof window !== 'undefined' &&
              typeof targetFn === 'function' &&
              targetFn.constructor?.name === 'AsyncFunction'
            ) {
              const currentProvider = getProvider()
              const currentChainId = currentProvider.network.chainId
              // if reference chainId isn't up to date
              if (chainIdRef.current !== currentChainId) {
                // if there is no ongoing chain set promise
                if (!chainSetPromise.current) {
                  // set ready to false before making changes
                  setReady(false)
                  // set chain set promise to new promise
                  chainSetPromise.current = setChainPromise()
                }
                // eslint-disable-next-line func-names
                return async function (this: any, ...args: any[]) {
                  // wait for existing chain set promise
                  await chainSetPromise.current
                  // return result of target function
                  return (targetFn as Function)(...args)
                }
              }
            }
            // pass through all other getters
            return Reflect.get(target, prop, reciever)
          },
        },
      ),
    [ready],
  )

  return <EnsContext.Provider value={value}>{children}</EnsContext.Provider>
}

function useEns() {
  const context = useContext(EnsContext)
  return context
}
export { useEns, EnsProvider }
